<template>
  <div class="card card-body shadow-sm sale-detailpage">

    <app-modal-confirm
      ref="change_status_modal"
      :modal-header-title="$t('announcements.confirm_title')"
      @confirmed="handlerUpdateStatus"
    >
      <template v-slot:body>
        <p class="confirm-body">ステータスを決済完了に変更すると元に戻すことはできません。本当に実行しますか？</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('sale.sale list')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.SALE_EDIT(queryString)"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >

        <template v-slot:table-menu-right>
          <button type="button"  class="btn btn-default m-r-10" @click="redirectDefaultValue">
            {{ $t('common.back') }}
          </button>
        </template>

        <template v-slot:body-cell-apartment_id="props">
          <td class="app-align-middle" style="width: 20%;">
            <p :data-original-title="getApartmentName(props.row.apartment_id)" style="color: #007bff;"
               @click="handlerApartmentNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
              {{props.row.apartment_name}}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-amount="props">
          <td class="app-align-middle" style="width: 15%;">
            <p :data-original-title="convertAmount(props.row.amount)" style="margin-top: revert; text-align: right;">
              {{convertAmount(props.row.amount)}}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-purchase_status="props">
          <td class="app-align-middle" style="width: 16%;">
            <div class="purchase_status">
              <p v-if="props.row.purchase_status==2" class="error" :data-original-title="convertAmount(props.row.purchase_status_text)" style="margin-top: 0;display: inline-block; margin-right: 10px; font-size: inherit">
                {{props.row.purchase_status_text}}
              </p>
              <p v-else :data-original-title="convertAmount(props.row.purchase_status_text)">
                {{props.row.purchase_status_text}}
              </p>
              <button
                v-if="props.row.purchase_status != 1"
                @click="handleBtnOpenModal(props.row)"
                class="btn btn-primary"
              >
                {{ $t("sale.edit_status") }}
              </button>
            </div>
          </td>
        </template>

      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
export default {
  name: "SaleList",
  components: {
    "app-basic-table": AppBasicTable,
    AppDateRangePicker,
  },
  data() {
    return {
      idCache: null,
      paramGetList: {},
      filters: {},
      columns: [
        {name: "id", label: this.$t("sale.id"), sortable: true, textAlign: 'text-center'},
        {name: "ordered_at", label: this.$t("sale.date_month"), sortable: true, textAlign: 'text-center'},
        {name: "apartment_id", label: this.$t("sale.mansion"), textAlign: 'text-center'},
        {name: "customer_name", label: this.$t("sale.customer_name")},
        {name: "user_number", label: this.$t("sale.customer_id"), sortable: true, textAlign: 'text-center'},
        {name: "amount", label: this.$t("sale.amount_tax_include"), sortable: false},
        {name: "purchase_status", label: this.$t("sale.status"), sortable: true},
      ],
      currentEntry : null,
      meta: {
        apartments: [],
      },
      endPoint: '',
      queryString : ''
    };
  },
  created() {
    _.forEach(this.$route.query, (value, columnString) => {
        if (value) {
          this.queryString = this.updateQueryStringParameter(this.queryString, columnString, value)
        }
    })

  },
  methods: {
    handlerApartmentNameClick(entry) {
      let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
      window.open(routeApartment.href, '_blank');
    },
    onResetAllSearch() {
      this.$router.push({name: this.$route.name,
        query: {
          'filters.from_date.between' : moment().format('YYYY-MM-DD'),
          'filters.to_date.between' : moment().format('YYYY-MM-DD'),
        }
      }).catch((er)=>{
        console.log(er)
      });
    },
    async redirectDefaultValue() {
      await this.$router.push({name: this.ROUTES.ADMIN.SALE_LIST,query: {
          'filters.from_date.between' : moment().format('YYYY-MM-DD')
        }});
    },
    onSearch() {
      // this.getTourSelectName();
    },
    getApartmentName(apartmentId) {
      let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
      if (apartment == undefined) {
        return '';
      }
      return apartment.name;
    },
    convertAmount(price) {
      if (price) {
        let number = Intl.NumberFormat("en-US");
        return "￥" + number.format(price) || "";
      } else {
        return "￥0";
      }
    },
    async handlerUpdateStatus(){
      if (this.currentEntry) {
        let res = null;
        let msg = "";
        res = await this.$request.patch(this.ENDPOINT.SALE_UPDATE(this.currentEntry.id));
        msg = this.$t("common.msg update ok")

        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.change_status_modal.hide();
        } else {
          this.currentEntry = null
          this.__noticeSuccess(msg);
          await this.$refs.basic_table.getEntries();
          this.$refs.change_status_modal.hide();
        }
      }
    },
    handleBtnOpenModal(entry){
      this.$refs.change_status_modal.show();
      this.currentEntry = entry
    },
    convertDate(date) {
      if (date) {
        return moment(String(date)).format('YYYY-MM-DD')
      } else {
        return "";
      }
    },
  },
  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
      this.meta = {
        apartments: res.data.apartments,
      }
      this.meta.apartments.unshift({
        id: "",
        name: "全て"
      })
    })
  },
}
</script>
